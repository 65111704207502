export default {
  PusherKey: '703d3a0be950105caaee',
  ISC_API_KEY: 'myapikey',
  GTM: 'GTM-KV53PFG',
  Segment: 'ymUHiduI8WgNZhGtB9P6eKlqd7sQlb4k',
  CustomerIOSiteID: 'cb04a5c94fd099c9a1aa',
  TrackingID: 'UA-66374966-17',
  Hotjar: 3210022,
  Sentry: 'https://7f37ab73cd3646118f9de9ab763e89e0@sentry.instacarro.com/16',
  baseURLs: {
    authentication: 'https://sandbox-api.instacarro.com/accounts',
    newAuthentication: 'https://account.sandbox.instacarro.com/v1/user',
    buyers: 'https://sandbox-api.instacarro.com/buyers/v2',
    dealers: 'https://sandbox-api.instacarro.com/dealers/v3',
    sales: 'https://sandbox-api.instacarro.com/buyers/v2',
    buyersV3: 'https://sandbox-api.instacarro.com/buyers/v3',
    accounts: 'https://sandbox-api.instacarro.com/accounts',
    metrics: 'http://sales-manager-metrics-service-sandbox.apps.instacarro.com',
    internals: 'https://sandbox-api.instacarro.com/internals',
    ninja_dashboard: 'https://sandbox-api.instacarro.com/ninja-dashboard-records/dashboard',
    mechanics: 'https://sandbox-api.instacarro.com/mechanics',
    logistics: 'https://sandbox-api.instacarro.com/logistics',
    baseIcApiDealers: 'https://sandbox-api.instacarro.com/ic-api-dealers',
    icApiDealers: 'https://sandbox-api.instacarro.com/ic-api-dealers/v1/dealers',
    auQueries: 'https://sandbox-api.instacarro.com/au_queries/v1',
    autobid: 'https://sandbox-api.instacarro.com/au_managements',
    preferences: 'https://notifications.sandbox.instacarro.com/order-cars',
    fincredit: 'https://sandbox-api.instacarro.com/fincredit_simulator_api/api',
    generateDoc: ' https://sandbox-api.instacarro.com/v1/pipelines/report/dealer/docs',
  },
  MixpanelToken: 'f25cc12b33512b2983a01a2853b1780a',
  IndiqueLink: 'https://cupom.instacarro.com/lojistas',
  GA_Dimentions: {
    dealership_name: 'dimension3',
    auction_id: 'dimension5',
    dealership_id: 'dimension6',
    bid_amount: 'dimension7',
    bid_type: 'dimension8',
    dealership_email: 'dimension9',
    selectedCategory: 'dimension10',
    carResults: 'dimension11',
    interactionType: 'dimension12',
  },
  DOMAIN_APP: '.sandbox.leilao.instacarro.com',
};
