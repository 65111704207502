import React, { useState, useCallback, useMemo, createContext, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSegment } from './useSegment';

function removeAcentos(newStringComAcento) {
  var semAcento = newStringComAcento.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return semAcento;
}

function hasHighLight(name, auction) {
  return auction?.summary?.highlights?.some(highlight => {
    return removeAcentos(highlight.description.toLowerCase()) === removeAcentos(name.toLowerCase());
  });
}

const vehicleHasBuyFor = auction => auction.summary?.buyForPrice?.enabled;

export function hasValityPaper(auction) {
  return hasHighLight('Laudo Cautelar: Aprovado', auction);
}

const vehiclePercentageFipe = auction => {
  let fipe = 0;
  const currentOffer = auction?.auction?.winningBid?.amount || 0;
  const marketPrice = auction.summary?.marketPrice;

  if (!!marketPrice && marketPrice > 100) {
    fipe = Math.trunc((100 * currentOffer) / marketPrice) || 0;
  }

  return fipe <= 70;
};

const vehicleFipe100k = auction => auction.summary?.marketPrice >= 100000;

const vehicleFipe70k = auction => auction.summary?.marketPrice >= 70000;

const vehicleScore80 = auction => {
  let score = 0;
  const goodPointsCount = Math.round(auction.summary?.goodPointsCount);

  if (!!goodPointsCount) {
    score = goodPointsCount;
  }

  return score >= 80;
};

const vehicleCloseToEnd = auction => {
  let closeToEnd = false;
  if (!!auction?.auction?.remainingTime) {
    closeToEnd = auction?.auction?.remainingTime <= 30 * 60 * 1000; // 30 minutes
  }

  return closeToEnd;
};

const vehicleMostViewed = auction => {
  let mostViewed = auction?.auction?.viewCount || 0;
  return mostViewed >= 8;
};

const vehicleBefore100k = auction => {
  let before100k = false;
  if (!!auction?.summary?.km) {
    before100k = auction?.summary?.km <= 100000;
  }

  return before100k;
};

export const buyFor = list => list.filter(vehicleHasBuyFor);
export const cautelar = list => list.filter(hasValityPaper);
export const fipe70 = list => list.filter(vehiclePercentageFipe);
export const fipe100k = list => list.filter(vehicleFipe100k);
export const fipe70k = list => list.filter(vehicleFipe70k);
export const score80 = list => list.filter(vehicleScore80);
export const before100k = list => list.filter(vehicleBefore100k);
export const mostViewed = list => list.filter(vehicleMostViewed);
export const closeToEnd = list => list.filter(vehicleCloseToEnd);

const getLengths = list => {
  let lengths = {
    buyFor: buyFor(list).length,
    cautelar: cautelar(list).length,
    fipe70: fipe70(list).length,
    fipe100k: fipe100k(list).length,
    fipe70k: fipe70k(list).length,
    score80: score80(list).length,
    before100k: before100k(list).length,
    mostViewed: mostViewed(list).length,
    closeToEnd: closeToEnd(list).length,
  };

  return lengths;
};

const FilterContext = createContext({});

export function FilterProvider({ children }) {
  const auctions = useSelector(state => state.auctions?.newOffers || []);
  const showFilter = useSelector(state => state.ui.filters.showNewOffersFilters);

  const [filterList, setFilterList] = useState([]);
  const [staticAuctions, setStaticAuctions] = useState([]);

  const { trackFilter } = useSegment();

  const toogleFilter = useCallback(
    filter => {
      if (filterList.includes(filter)) {
        setFilterList(old => old.filter(f => f !== filter));
      } else {
        setFilterList(old => [...old, filter]);
      }
    },
    [filterList]
  );

  const filtredAuctions = useMemo(() => {
    let list = showFilter ? staticAuctions : auctions;

    if (auctions.length === 0) return { data: [], lengths: {} };

    if (filterList.includes('buyFor')) list = buyFor(list);
    if (filterList.includes('cautelar')) list = cautelar(list);
    if (filterList.includes('fipe70')) list = fipe70(list);
    if (filterList.includes('fipe70k')) list = fipe70k(list);
    if (filterList.includes('fipe100k')) list = fipe100k(list);
    if (filterList.includes('score80')) list = score80(list);
    if (filterList.includes('before100k')) list = before100k(list);
    if (filterList.includes('mostViewed')) list = mostViewed(list);
    if (filterList.includes('closeToEnd')) list = closeToEnd(list);

    const lengths = getLengths(list);
    return { data: list, lengths };
  }, [auctions, filterList]);

  const clearFilter = useCallback(() => {
    setFilterList([]);
    sessionStorage.removeItem('categories');
  }, [filtredAuctions]);

  useEffect(() => {
    if (showFilter) setStaticAuctions(auctions);
  }, [showFilter]);

  useEffect(() => {
    if (filtredAuctions.data.length === 0 && filterList.length > 0) {
      clearFilter();
    }
  }, [filtredAuctions, filterList]);

  useEffect(() => {
    if (filterList.length > 0) {
      trackFilter('categories', filterList, filtredAuctions?.data?.length);
    }
  }, [filterList, filtredAuctions]);

  return (
    <FilterContext.Provider
      value={{
        filterList,
        filtredAuctions,
        toogleFilter,
        clearFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export function useFilter() {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within an FilterProvider!!');
  }

  return context;
}
