export default {
  LIST_FAVORITES_REQUEST: '@favorites/LIST_FAVORITES_REQUEST',
  LIST_FAVORITES_SUCCESS: '@favorites/LIST_FAVORITES_SUCCESS',
  LIST_FAVORITES_FAILURE: '@favorites/LIST_FAVORITES_FAILURE',

  INSERT_FAVORITE_REQUEST: '@favorites/INSERT_FAVORITE_REQUEST',
  INSERT_FAVORITE_SUCCESS: '@favorites/INSERT_FAVORITE_SUCCESS',
  INSERT_FAVORITE_FAILURE: '@favorites/INSERT_FAVORITE_FAILURE',

  REMOVE_FAVORITE_REQUEST: '@favorites/REMOVE_FAVORITE_REQUEST',
  REMOVE_FAVORITE_SUCCESS: '@favorites/REMOVE_FAVORITE_SUCCESS',
  REMOVE_FAVORITE_FAILURE: '@favorites/REMOVE_FAVORITE_FAILURE',
};
