import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useTimer } from '../../hooks/useTimer';
import { timingService } from '../../services/TimingService';

const TimingAuctionContext = createContext({
  formattedRemainingTime: '--:--:--',
  isBlinking: false,
  isStop: false,
  milisecondsLeft: 100,
  secondsLeft: 100,
  totalSeconds: 100,
});

const TimingAuctionContextProvider = ({ children, auction }) => {
  const isStop = useMemo(() => !auction?.onGoing, [auction]);
  const initialDuration = useMemo(() => auction?.initialDuration, [auction]);

  const remainingTime = useMemo(() => {
    const cachedRemainingTime = timingService.getAuction(auction?._id);
    if (cachedRemainingTime) return cachedRemainingTime;

    timingService.setAuction(auction?._id, auction?.remainingTime);
    return auction?.remainingTime;
  }, [auction]);

  const timing = useTimer(remainingTime);

  const contextValue = useMemo(
    () => ({
      isStop,
      totalSeconds: initialDuration / 1000,
      ...timing,
    }),
    [isStop, timing]
  );

  return (
    <TimingAuctionContext.Provider value={contextValue}>
      {typeof children === 'function' ? children(contextValue) : children}
    </TimingAuctionContext.Provider>
  );
};

const useTimingAuctionContext = () => {
  const context = useContext(TimingAuctionContext);
  return context;
};

const UseTimingAuctionContextComponent = ({ children }) => {
  const context = useTimingAuctionContext();
  return children(context);
};

export {
  TimingAuctionContext,
  TimingAuctionContextProvider,
  useTimingAuctionContext,
  UseTimingAuctionContextComponent,
};
