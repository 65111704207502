import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LoginPage, ForgotPasswordPage, ResetPasswordPage } from 'pages/AuthPages';
import Terms from 'pages/Terms';

export const UnloggedRoutes = () => {
  const { user } = useSelector(state => state.authentication);
  const history = useHistory();

  useEffect(() => {
    if (!user.isLoggedIn()) return history.push('/login');
  }, [user]);

  return (
    <Switch>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/esqueceu-senha" exact component={ForgotPasswordPage} />
      <Route path="/resetar-senha" exact component={ResetPasswordPage} />
      <Route path="/terms" exact component={Terms} />
    </Switch>
  );
};
