import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../enhanced/Modal';
import withMakeOffer from '../../hoc/WithMakeOffer';
import { toggleModal } from '../../../store/actions/ui.actions';
import { AUCTION_BUY_KEY_CONFIRMATION } from '../';
import { AuctionItemTimer } from '../../enhanced/AuctionItemTimer';
import { TimingAuctionContextProvider } from '../../../context/TimingAuctionContext';
import {
  Content,
  VehicleNameAndInfos,
  NegotiationExclusivityInfo,
  ErrorMessage,
  CancelButton,
} from './styles';
import { PrimaryButton } from '../../Buttons/old';
import { getSelectedAuctionRemainingTime } from '../../../store/selectors/auctionSelectors';
import { useBid } from '../../../hooks/useBid';
import DealersDropdown from '../../Selects/DealersDropdown';
import TaxProfilesDropdown from '../../Selects/TaxProfilesDropdown';
import { rem } from 'polished';
import { onBidError } from '../../../store/actions/auction.actions';
import { useSegment } from '../../../hooks/useSegment';
import { toAmount } from '../../../utils/number';

import { saveCreditSimulation } from '../../../services/fincredit.js';
import { useDefaultProfile } from '../../../hooks/useDefaultProfile';

const buyNowLgStyles = {
  content: {
    position: 'relative',
    maxHeight: `calc(100% - ${rem(10)})`,
    maxWidth: '21.25rem',
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const buyNowMdStyles = {
  content: {
    position: 'relative',
    maxHeight: `calc(100% - ${rem(10)})`,
    maxWidth: `50%`,
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const ConfirmBuyNowModal = ({
  submitBuyNow,
  makeOfferLoading,
  selectedAuction,
  bidErrorMessage,
  show,
  toggle,
  selectedAuctionRemainingTime,
  dealerId,
  onDealerChange,
  relatedDealers,
  showDealersDropdown,
  selectedTaxProfile,
  onTaxProfileChange,
  taxProfiles,
}) => {
  const { extras } = useSelector(state => state.ui.modals.auctionBuyConfirmation);
  const { auction, section, isRecommended = false, fincreditInfo, showFin } = extras || {};

  const { negotiationExclusivityLabel } = useBid(selectedAuction);
  const dispatch = useDispatch();
  const { trackConfirmBid } = useSegment(isRecommended);

  const { user } = useSelector(state => state.authentication);
  const defaultProfile = useDefaultProfile();

  const _auction = useMemo(() => {
    return {
      ...selectedAuction?.auction,
      remainingTime: selectedAuctionRemainingTime,
    };
  }, [selectedAuction, selectedAuctionRemainingTime]);

  const getWindowScreen = useMemo(() => {
    if (window.screen.width >= 1025) return buyNowLgStyles;

    return window.screen.width < 1025 && window.screen.width > 414 ? buyNowMdStyles : null;
  }, [window.screen.width]);

  useEffect(() => {
    if (!show) {
      dispatch(onBidError(''));
    }
  }, [show]);

  return (
    <TimingAuctionContextProvider auction={_auction}>
      <Modal
        toggle={() => {
          toggle({ key: AUCTION_BUY_KEY_CONFIRMATION, show: false });
        }}
        show={show}
        style={show ? getWindowScreen : undefined}
      >
        <Content>
          <VehicleNameAndInfos>
            {selectedAuction?.summary?.make} {selectedAuction?.summary?.model}{' '}
            {selectedAuction?.summary?.yearLabel} - {selectedAuction?.summary?.version}{' '}
            {selectedAuction?.summary?.kmLabel}
          </VehicleNameAndInfos>

          <AuctionItemTimer showBuyNowButton={false} auction={selectedAuction} />

          <NegotiationExclusivityInfo>
            Ao confirmar, você estará encerrando o leilão no valor de{' '}
            <strong>{negotiationExclusivityLabel}</strong> com alta chance de compra. Entraremos em
            contato caso haja uma negociação diferente por parte do cliente (vendedor). Acompanhe na
            plataforma em "Minhas Compras"!
          </NegotiationExclusivityInfo>

          <ErrorMessage>{bidErrorMessage}</ErrorMessage>

          {showDealersDropdown && (
            <DealersDropdown
              selectedDealer={dealerId}
              onDealerChange={onDealerChange}
              relatedDealers={relatedDealers}
            />
          )}

          {dealerId && taxProfiles.length > 0 && (
            <TaxProfilesDropdown
              selectedTaxProfile={selectedTaxProfile}
              onTaxProfileChange={onTaxProfileChange}
              taxProfiles={taxProfiles}
            />
          )}

          <PrimaryButton
            isLoading={makeOfferLoading}
            onClick={async () => {
              await submitBuyNow();
              showFin &&
                (await saveCreditSimulation(user.id, defaultProfile, fincreditInfo, auction));
              trackConfirmBid({
                inspection_id: auction._id,
                auction_id: auction?.auction.idAuction,
                currentBid: auction.auction.winningBid.amount,
                exclusivityBid: toAmount(auction.summary.buynowPrice.amount),
                type: showFin ? 'exclusivityBidFinCredit' : 'exclusivityBid',
                entry_amount: fincreditInfo?.entryAmount,
                quantity_installments: fincreditInfo?.quantityInstallments,
                date_hour: new Date().getTime(),
                section,
              });
            }}
            negotiateExclusivity
            modal
          >
            Encerrar leilão
          </PrimaryButton>

          <CancelButton onClick={() => toggle({ key: AUCTION_BUY_KEY_CONFIRMATION, show: false })}>
            Cancelar
          </CancelButton>
        </Content>
      </Modal>
    </TimingAuctionContextProvider>
  );
};

ConfirmBuyNowModal.propTypes = {
  submitBuyNow: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  selectedAuction: PropTypes.object,
  makeOfferLoading: PropTypes.bool,
  bidErrorMessage: PropTypes.string,
  show: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  toggle: bindActionCreators(toggleModal, dispatch),
});

const mapStateToProps = state => ({
  show: state.ui.modals.auctionBuyConfirmation.show,
  selectedAuctionRemainingTime: getSelectedAuctionRemainingTime(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMakeOffer(ConfirmBuyNowModal));
