import objectAssign from 'object-assign';
import ActionTypes from '../../constants/actionTypes';
import Types from './types';
import { updateAuctionTimer } from '../../utils/auction';

const INITIAL_STATE = {
  vehicleFavorited: [],
  vehicleDeletedOnFavorite: [],
  listFavorites: [],
  loading: false,
  error: false,
};

export function favorites(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.INSERT_FAVORITE_REQUEST:
      return { ...state };
    case Types.INSERT_FAVORITE_SUCCESS:
      return { ...state, vehicleFavorited: action.payload };
    case Types.INSERT_FAVORITE_FAILURE:
      return { ...state, vehicleFavorited: {}, error: action.error };
    case Types.REMOVE_FAVORITE_REQUEST:
      return { ...state };
    case Types.REMOVE_FAVORITE_SUCCESS:
      return { ...state, vehicleDeletedOnFavorite: action.payload };
    case Types.REMOVE_FAVORITE_FAILURE:
      return { ...state, vehicleDeletedOnFavorite: {}, error: action.error };
    case Types.LIST_FAVORITES_REQUEST:
      return { ...state, loading: true };
    case Types.LIST_FAVORITES_SUCCESS:
      return { ...state, loading: false, error: false, listFavorites: action.payload };
    case Types.LIST_FAVORITES_FAILURE:
      return { ...state, loading: false, error: action.error, listFavorites: [] };
    case ActionTypes.Auction.SetDuration:
      return {
        ...state,
        listFavorites: state.listFavorites.map(favorite => {
          if (favorite._id === action.id) return updateAuctionTimer(favorite, action);
        }),
      };
    default:
      return state;
  }
}
