import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  deleteVehicleOnFavorites,
  insertVehicleOnFavorites,
  listUserFavorites,
} from 'store/favorites/effects';

export function useFavorites(inspectionId) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [favorited, setFavorited] = useState(false);
  const [favoring, setFavoring] = useState(false);
  const [disfavoring, setDisfavoring] = useState(false);

  const { listFavorites, vehicleFavorited, vehicleDeletedOnFavorite } = useSelector(
    state => state.favorites
  );

  const addFavorite = useCallback(async () => {
    setFavoring(true);
    await dispatch(insertVehicleOnFavorites(inspectionId));
    setFavoring(false);
  }, [inspectionId]);

  const removeFavorite = useCallback(async () => {
    setDisfavoring(true);
    await dispatch(deleteVehicleOnFavorites(inspectionId));
    if (pathname.includes('favoritos')) {
      await dispatch(listUserFavorites());
    }
    setDisfavoring(false);
  }, [inspectionId, pathname]);

  useEffect(() => {
    if (
      !!vehicleFavorited?.find(
        favorite => favorite.inspectionId === inspectionId && favorite.isActive
      )
    )
      setFavorited(true);
    else setFavorited(false);
  }, [vehicleFavorited, inspectionId]);

  useEffect(() => {
    if (
      !!vehicleDeletedOnFavorite?.find(
        favorite => favorite.inspectionId === inspectionId && favorite.isActive
      )
    )
      setFavorited(true);
    else setFavorited(false);
  }, [vehicleDeletedOnFavorite, inspectionId]);

  useEffect(() => {
    if (
      !!listFavorites?.find(favorite => favorite === inspectionId || favorite?._id === inspectionId)
    )
      setFavorited(true);
    else setFavorited(false);
  }, [listFavorites, inspectionId]);

  return {
    favorited,
    addFavorite,
    removeFavorite,
    favoring,
    disfavoring,
  };
}
