import balboaFont from './styles/balboa-w01-bold.ttf';
import balboaFontCondensed from './styles/balboa-condensed.otf';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset};
  strong {
    font-weight: 700;
  }
  p {
    margin: 0.5rem 0 1rem 0;
  }
  @font-face {
    font-family: 'Balboa W01 Cond';
    src: url(${balboaFont}) format('truetype');
  }

  @font-face {
    font-family: 'Balboa Condensed';
    src: url(${balboaFontCondensed}) format('truetype');
  }

`;
