import Types from './types';

export const listFavoritesRequest = () => ({
  type: Types.LIST_FAVORITES_REQUEST,
});

export const listFavoritesSuccess = payload => ({
  type: Types.LIST_FAVORITES_SUCCESS,
  payload,
});

export const listFavoritesFailure = error => ({
  type: Types.LIST_FAVORITES_FAILURE,
  error,
});

export const insertFavoriteRequest = () => ({
  type: Types.INSERT_FAVORITE_REQUEST,
});

export const insertFavoriteSuccess = payload => ({
  type: Types.INSERT_FAVORITE_SUCCESS,
  payload,
});

export const insertFavoriteFailure = error => ({
  type: Types.INSERT_FAVORITE_FAILURE,
  error,
});

export const removeFavoriteRequest = () => ({
  type: Types.REMOVE_FAVORITE_REQUEST,
});

export const removeFavoriteSuccess = payload => ({
  type: Types.REMOVE_FAVORITE_SUCCESS,
  payload,
});

export const removeFavoriteFailure = error => ({
  type: Types.REMOVE_FAVORITE_FAILURE,
  error,
});
