import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from 'components/header';
import Footer from 'components/shared/Footer';
import AuctionNewOffersCard from 'components/AuctionCards/AuctionNewOffersCard';
import { Loading } from 'components';
import { EmptyState } from 'components/enhanced/EmptyState';
import { listUserFavorites } from 'store/favorites/effects';
import { useSegment } from 'hooks/useSegment';

import { Container, Content, List } from './styles';

export const FavoritesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { page } = useSegment();
  const { listFavorites } = useSelector(state => state.favorites);

  const [loading, setLoading] = useState();

  useEffect(() => {
    page('Meus Favoritos');
    async function renderFavorites() {
      setLoading(true);
      await dispatch(listUserFavorites());
      setLoading(false);
    }
    renderFavorites();
  }, []);

  const useLayout = useCallback(() => {
    const filtered = listFavorites?.filter(favorite => favorite?.auction?.status !== 'FINISHED');

    if (!!filtered.length) {
      return (
        <List>
          {filtered?.map(favorite => (
            <li key={favorite._id}>
              <AuctionNewOffersCard auction={favorite} />
            </li>
          ))}
        </List>
      );
    }

    return (
      <EmptyState
        title="Você não favoritou nenhum leilão ainda"
        description="Vá para a tela de leilões e favorite o seu primeiro carro."
        buttons={[
          {
            label: 'Ver leilões ativos',
            action: () => history.push('/'),
            variant: 'ghost.default',
          },
        ]}
      />
    );
  }, [listFavorites]);

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <Header />
          <Content>
            <h1 className="container-title">Favoritos</h1>
            {useLayout()}
          </Content>
          <Footer />
        </React.Fragment>
      )}
    </Container>
  );
};
