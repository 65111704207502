import React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import classNames from 'classnames';

import { Button } from './enhanced/Button';
import PaymentIcon from '../images/icons/paymentIcon.svg';

const DialogStyles = styled.div`
  max-width: 500px;

  line-height: 22px;

  background-color: #ffffff;
  border-radius: 4px;

  h3 {
    font-weight: ${themeGet('fontWeights.headings.medium')};
    font-family: ${themeGet('fonts.headings')};
    color: #ffffff;
    text-align: center;
    border-radius: 4px;
    padding: 0.6rem 1rem;

    background-color: ${props => {
      switch (props.variant) {
        case 'success':
          return themeGet('colors.secondaries.Uno')(props);
        case 'info':
          return themeGet('colors.primaries.Soul')(props);
        case 'error':
          return themeGet('colors.alerts.Ferrari')(props);
      }
    }};

    ${({ payment }) =>
      payment &&
      css`
        font: 700 normal 1.15rem/1em 'Roboto', sans-serif;
        color: #2f3741;

        padding: 0;
        margin-left: 12px;

        background-color: transparent;
        border-radius: 0;
      `}
  }
  .dialog-content {
    padding: 1rem 1rem 0 1rem;
    font-family: ${themeGet('fonts.paragraphs')};
    text-align: center;
  }
  .dialog-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    padding: 0 ${themeGet('space.4')};

    button {
      flex: 1 0;
      min-width: 120px;
      transition: all ease-in-out 0.2s;
    }

    &--only-cta {
      button {
        flex: initial;
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 0.6rem 1rem;

  background-color: #ffd88c;
  border-radius: 4px 4px 0 0;
`;

export const Dialog = ({
  header,
  children,
  onCTAClick,
  onSecondaryBtnClick,
  variant = 'success',
  primaryBtnVariant = 'primary',
  secondaryBtnVariant = 'secondary',
  primaryBtnLabel = 'OK',
  secondaryBtnLabel = 'Cancelar',
  payment = false,
  isLoading = false,
  loading,
}) => {
  const btnWrapperClasses = classNames({
    'dialog-buttons': true,
    'dialog-buttons--only-cta': !onSecondaryBtnClick,
  });
  return (
    <DialogStyles variant={variant} payment={payment}>
      {payment ? (
        <Content>
          <img src={PaymentIcon} />
          <h3>{header}</h3>
        </Content>
      ) : (
        <h3>{header}</h3>
      )}

      <div className="dialog-content">{children}</div>
      <div className={btnWrapperClasses}>
        {onSecondaryBtnClick && (
          <Button mr={3} onClick={onSecondaryBtnClick} variant={secondaryBtnVariant}>
            {secondaryBtnLabel}
          </Button>
        )}
        <Button onClick={onCTAClick} variant={primaryBtnVariant} disabled={isLoading}>
          {isLoading ? loading : primaryBtnLabel}
        </Button>
      </div>
    </DialogStyles>
  );
};
