import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  padding: 1.125rem;

  background-color: #fff;

  border: 0.063rem solid #d2d4d7;
  border-radius: 0.5rem;

  box-shadow: 0 0.25rem 0.25rem rgba(34, 116, 165, 0.1);

  ${({ isHighlight }) =>
    isHighlight &&
    css`
      border-color: #fcab10;
    `}
`;

export const VehicleFipeTag = styled.div`
  position: absolute;
  top: 15px;
  left: 10px;

  padding: 0.5rem 0.75rem;

  font: 700 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #438a4c;

  background-color: #e2f1e6;
  border-radius: 0.375rem;

  z-index: 1;
`;

export const VehicleCarouselPhotoContainer = styled.div`
  margin-bottom: 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.8rem;
`;

export const VehicleNameAndModel = styled(Link)`
  font: 700 normal 1rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const FavoriteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  background-color: transparent;
  border: none;

  outline: none;
  cursor: pointer;
`;

export const VehicleDescription = styled.span`
  margin-bottom: 0.8rem;

  font: 400 normal 0.875rem/1.8em 'Roboto', sans-serif;
  color: #757778;
`;

export const VehicleApprovalAndLocation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`;

export const VehicleApprovalContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const VehicleApprovalLabel = styled(Link)`
  font: 400 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #5d5d5e;
  text-decoration: none;

  cursor: pointer;
`;
export const VehicleLocationContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const VehicleLocationLabel = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #22aa52;
`;

export const ShippingPopover = styled.span`
  position: absolute;
  top: 30px;
  right: 0;
  left: unset;

  min-width: 15rem;
  padding: 0.5rem;

  font: 700 normal 0.75rem/1.3em 'Roboto', sans-serif;
  color: #5d5d5e;

  background: white;

  border-top: 2px solid rgb(61 184 112);
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 10px 0px;

  z-index: 99;

  &:before {
    content: '';

    position: absolute;
    bottom: 100%;
    left: 16.5em;

    width: 0;
    height: 0;

    border: 0.5rem solid transparent;
    border-top: none;
    border-bottom-color: rgb(61 184 112);

    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }
`;

export const VehicleTemperatureContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 0.8rem;
`;

export const LastBidContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
`;

export const LastBidLabel = styled.span`
  margin-bottom: 0.5rem;

  font: 400 normal 1rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const LastBidValue = styled.span`
  font: 400 normal 1rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px 0;

  width: 100%;
  min-height: 92px;
`;

export const TypeOffersActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 14px;

  width: 100%;
`;

export const VehicleRestrictions = styled.span`
  margin-top: 16px;

  font: 400 normal 0.8rem/1.5em 'Roboto', sans-serif;
  color: #757778;
`;

export const BuyForButton = styled.button`
  width: 100%;
  padding: 12px 0;

  font: 400 normal 16px/1em 'Roboto', sans-serif;
  color: #000;
  text-align: center;

  background-color: #f2c94c;

  border: none;
  border-radius: 8px;

  outline: none;
  cursor: pointer;

  ${props =>
    props.smallerSize &&
    css`
      font-size: 14.5px;
    `};
`;
