import axios from 'axios';

import {
  insertFavoriteFailure,
  insertFavoriteRequest,
  insertFavoriteSuccess,
  listFavoritesFailure,
  listFavoritesRequest,
  listFavoritesSuccess,
  removeFavoriteFailure,
  removeFavoriteRequest,
  removeFavoriteSuccess,
} from './actions';
import AppConstants from '../../constants/appConstants';
import { getToken } from '../../utils/auth';
import { getCurrentElapsed } from '../../utils/dateUtils';
import { formatAuctionObjectKeys } from '../../utils/auction';

export const listUserFavorites = () => async (dispatch, getState) => {
  dispatch(listFavoritesRequest());
  try {
    const user = getState().authentication.user;
    const {
      data: { data },
    } = await axios.get(`${AppConstants.API.userFavorite}/list`, {
      headers: {
        Authorization: 'Bearer ' + getToken().access_token,
      },
    });
    const favorites = [];

    data.forEach(favorite => {
      favorites.push(favorite.inspectionId);
    });

    if (window.location.pathname.includes('favoritos')) {
      const requestStartTime = getCurrentElapsed();
      const {
        data: { result },
      } = await axios.get(`${AppConstants.API.auctions}/vehicle-list?v=${favorites.join(',')}`, {
        headers: {
          Authorization: 'Bearer ' + getToken().access_token,
        },
      });

      result.forEach(favorite => formatAuctionObjectKeys(favorite, requestStartTime, user));
      dispatch(listFavoritesSuccess(result));
    } else {
      dispatch(listFavoritesSuccess(favorites));
    }
  } catch (error) {
    dispatch(listFavoritesFailure(error));
  }
};

export const insertVehicleOnFavorites = inspectionId => async dispatch => {
  dispatch(insertFavoriteRequest());
  try {
    const {
      data: { data },
    } = await axios.post(
      AppConstants.API.userFavorite,
      { inspectionId },
      {
        headers: {
          Authorization: 'Bearer ' + getToken().access_token,
        },
      }
    );
    dispatch(insertFavoriteSuccess(data));
  } catch (error) {
    dispatch(insertFavoriteFailure(error));
  }
};

export const deleteVehicleOnFavorites = inspectionId => async dispatch => {
  dispatch(removeFavoriteRequest());
  try {
    const {
      data: { data },
    } = await axios.delete(AppConstants.API.userFavorite, {
      headers: { Authorization: 'Bearer ' + getToken().access_token },
      data: { inspectionId },
    });
    dispatch(removeFavoriteSuccess(data));
  } catch (error) {
    dispatch(removeFavoriteFailure(error));
  }
};
