import React, { useCallback } from 'react';
import { Switch, Route as ReactRoute, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Purchases,
  PurchasesProvider,
  NewPurchasesProvider,
  FavoritesPage,
  TaxProfiles,
  TaxProfileForm,
  UpdateContactInfoPage,
  PreferencesPage,
  DetailsPage,
  NinjaDashboardPage,
  AuctionsPage,
} from 'pages';

import { UserContextProvider } from 'context/UserProfileContext';
import { userProfileService } from 'services/user-profile';

const Route = ({ children, onlyNinja = false, ...props }) => {
  const { user } = useSelector(state => state.authentication);

  const useRender = useCallback(() => {
    if (onlyNinja) {
      return user.isNinja() ? children : <Redirect to="/" />;
    }

    return children;
  }, [user, onlyNinja, children]);

  return <ReactRoute children={useRender} {...props} />;
};

export const LoggedRoutes = props => (
  <Switch>
    <Route path="/detalhes/:id">
      <DetailsPage />
    </Route>
    {/*  <Route path="/compras">
      <PurchasesProvider>
        <Purchases />
      </PurchasesProvider>
    </Route> */}
    <Route path="/compras">
      <NewPurchasesProvider>
        <Purchases />
      </NewPurchasesProvider>
    </Route>
    <Route path="/favoritos">
      <FavoritesPage />
    </Route>
    <Route path="/perfis">
      <TaxProfiles />
    </Route>
    <Route path="/perfis/novo">
      <TaxProfileForm />
    </Route>
    <Route path="/ferramenta-comercial" onlyNinja>
      <NinjaDashboardPage />
    </Route>
    <Route path="/atualizar-perfil">
      <UpdateContactInfoPage />
    </Route>
    <Route path="/preferencias">
      <PreferencesPage />
    </Route>
    <Route path="/">
      <UserContextProvider service={userProfileService} notifier={console}>
        <AuctionsPage />
      </UserContextProvider>
    </Route>
  </Switch>
);
