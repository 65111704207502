import { combineReducers } from 'redux';

import auctions from './auctions.reducer';
import authentication from './authentication.reducer';
import user from './user.reducer';
import timer from './timer.reducer';
import ui from './ui.reducer';
import stockCars from './stock-cars.reducer';
import inspections from './inspections.reducer';
import settings from './settings.reducer';
import commercialService from '../../services/commercial';
import { autoBid, listAutoBidVehicles, listAllAutoBidVehicles } from '../autobid/reducers';
import { tooltip } from '../tooltip/reducers';
import { orderCars } from '../orderCars/reducers';
import { favorites } from '../favorites/reducers';

const rootReducer = combineReducers({
  auctions,
  authentication,
  user,
  timer,
  ui,
  stockCars,
  inspections,
  settings,
  autoBid,
  listAutoBidVehicles,
  listAllAutoBidVehicles,
  tooltip,
  orderCars,
  favorites,
  [commercialService.reducerPath]: commercialService.reducer,
});

export default rootReducer;
