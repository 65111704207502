import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { startTimer, stopTimer } from './store/actions/timer.actions';

import theme from './design-system';
import { fetchAuction } from './store/actions/auction.actions';
import { fetchRelatedDealers } from './store/actions/user.actions';
import NotificationWrapper from './components/shared/notification/NotificationWrapper';
import AuctionPusher from './components/shared/pusher/AuctionPusher';
import { AppProvider } from './context';
import { gtmTracker, gtmVariables } from './utils/gtmTracker';
// import Onboarding from './components/Onboarding';

import {
  SingleOfferModal,
  WonModal,
  LostModal,
  BuyNowModal,
  SuccessModal,
  ConfirmMakeOfferModal,
  BuyFor,
  ConfirmBuyFor,
} from './components/Modals';
import { AcceptedModal, RejectedModal } from './components/AuctionList/modals';
import { ToastContainer } from './components/toast-notification-container';
import { saveFirstLoginOfTheDay } from './utils/freeShippingDialog';
import { checkToken } from './utils';
import TooltipFeedback from './components/Tooltips/TooltipFeedback';
import TooltipFeedbackOld from './components/Tooltips/TooltipFeedback/old';
import { FiltersContextProvider } from './context/AuctionFilters';
import { userFilterService } from './services/UserFilterService';
import AppConstants from './constants/appConstants';
import Pusher from 'pusher-js';

import { AutoBidOfferModal } from './components/Modals/AutoBid/AutomaticOffer';

import MoreOptions from './components/Modals/AutoBid/MoreOptions';
import RemoveAutomaticOffer from './components/Modals/AutoBid/RemoveAutomaticOffer';
import TiebreakerRules from './components/Modals/AutoBid/TiebreakerRules';
import ConfirmAutomaticOffer from './components/Modals/AutoBid/ConfirmAutomaticOffer';
import ConfirmBuyNowModal from './components/Modals/ConfirmBuyNowModal';

import { deauthenticate } from './store/actions/authentication.actions';
import { FlagProvider } from '@unleash/proxy-client-react';
import { FilterProvider } from './hooks/useFilter';

import { PreferencesModal } from './components/Modals/PreferencesModal';
import { LoggedRoutes, UnloggedRoutes } from './routes';
import { GlobalStyle } from './App.styles';
import { Temperature } from './components/Temperature';
import { ProductNF } from './components/Modals/ProductNF';
import { useHistory } from 'react-router-dom';

export const pusher = new Pusher(AppConstants.PusherKey, {
  encrypted: true,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const unleashConfig = {
  url:
    process.env.IC_ENV === 'production'
      ? 'https://unleash-proxy.instacarro.com/proxy'
      : 'https://proxy-unleash.instacarro.com/proxy',
  clientKey: 'secret-one',
  refreshInterval: 60,
  appName: 'lojas-web',
  environment: process.env.IC_ENV === 'production' ? 'prd' : 'dev',
};

const App = () => {
  const dispatch = useDispatch();
  const notifier = React.useRef();
  const { authentication: auth, user, auctions } = useSelector(state => state);
  const history = useHistory();

  React.useEffect(() => {
    const { user: _user } = auth;

    gtmTracker({
      [gtmVariables.USER_EMAIL]: _user.email,
      [gtmVariables.IS_NINJA]: _user.isNinja(),
      [gtmVariables.USER_DEALERSHIP]: _user.dealershipName,
      [gtmVariables.BUYER_ID]: user.buyerId,
    });
  }, [user, auth]);

  React.useEffect(() => {
    dispatch(startTimer());

    return () => {
      dispatch(stopTimer());
    };
  }, []);

  React.useEffect(() => {
    const { user: _user } = auth;
    if (!_user.isLoggedIn()) return;

    if (_user.email && _user.isNinja()) {
      checkToken()
        .then(async () => {
          dispatch(fetchRelatedDealers());
        })
        .catch(() => dispatch(deauthenticate(() => history.push('/login'))));
    }

    if (auctions.detailedAuction._id) {
      dispatch(fetchAuction(auctions.detailedAuction._id));
    }
  }, [auth]);

  React.useEffect(() => {
    saveFirstLoginOfTheDay();
  }, []);

  return (
    <FlagProvider config={unleashConfig}>
      <FiltersContextProvider service={userFilterService} notify={console}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <AppProvider>
              <FilterProvider>
                <GlobalStyle />

                {auth.user.isLoggedIn() ? <LoggedRoutes /> : <UnloggedRoutes />}

                {/** Start Modals */}
                <WonModal />
                <LostModal />
                <AcceptedModal />
                <RejectedModal />
                <SingleOfferModal />
                <BuyNowModal />
                <SuccessModal />
                <ConfirmMakeOfferModal />
                <AutoBidOfferModal />
                <MoreOptions />
                <RemoveAutomaticOffer />
                <TiebreakerRules />
                <ConfirmAutomaticOffer />
                <ConfirmBuyNowModal />
                <PreferencesModal />
                <Temperature.KnowMore />
                <ProductNF />
                <BuyFor />
                <ConfirmBuyFor />
                {/** End Modals */}

                {/* <Onboarding /> */}
                <ToastContainer />

                {/** Start Tooltips */}
                <TooltipFeedback />
                <TooltipFeedbackOld />
                {/** End Tooltips */}

                {process.env.NODE_ENV !== 'test' && (
                  <React.Fragment>
                    <NotificationWrapper ref={notifier} />
                    <AuctionPusher
                      pusher={pusher}
                      notifier={notifier ? notifier.current : undefined}
                      auctions={auctions.allAuctions}
                    />
                  </React.Fragment>
                )}
              </FilterProvider>
            </AppProvider>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </FiltersContextProvider>
    </FlagProvider>
  );
};

export default App;
