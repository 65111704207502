import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { MdAccountCircle } from 'react-icons/md';
import { rem } from 'polished';

import { getAuthenticatedUser } from '../../store/selectors';
import { Box, Flex, Text } from '../abstract';
import { TagFincreditLimit } from '../../components/TagFincreditLimit';

const Root = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-bottom: ${themeGet('space.2')};
  border-bottom: 1px solid #e7e7e7;
`;

const UserInfo = ({ user, limitValue, showTag, ...props }) => {
  if (!user.isLoggedIn()) return null;

  const [size, setSize] = useState(window.screen.width);

  window.addEventListener('resize', () => setSize(window.screen.width));

  return (
    <Root {...props}>
      <Box mr={1} mt={rem(-4)}>
        <MdAccountCircle size={51} color="#AFB2B7" />
      </Box>
      <Flex flexDirection="column" style={{ overflow: 'hidden' }}>
        <Text
          as="h1"
          color="neutrals.EclipseE600"
          fontFamily="headings"
          fontWeight="bold"
          fontSize={1}
          mb={0}
          lineHeight="headings"
          style={{ wordBreak: 'break-word', textTransform: 'uppercase' }}
        >
          {user.getFullName()}
        </Text>
        <Text
          as="h2"
          color="neutrals.EclipseE500"
          fontFamily="paragraphs"
          fontWeight="paragraphs.regular"
          fontSize={1}
          mb={0}
          lineHeight="paragraphs"
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {user.email}
        </Text>
        {limitValue > 0 && showTag && (
          <a
            href="/pagamento-facilitado/simule"
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <TagFincreditLimit limitValue={limitValue} />
          </a>
        )}
      </Flex>
    </Root>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: getAuthenticatedUser(state).user,
});

export default connect(mapStateToProps)(UserInfo);
