import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  max-width: 1280px;
  width: 100%;
  margin: 5rem auto;

  .container-title {
    margin-bottom: 2rem;
    font: 700 normal 1.5rem/1em 'Nunito', sans-serif;
    color: #2f3741;
  }

  @media screen and (min-width: 0) and (max-width: 1024px) {
    padding: 0 1rem;
  }
`;

export const List = styled.ul`
  display: grid;
  grid-gap: 0.625rem;
  flex: 0 1 auto;

  @media screen and (min-width: 0) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1366px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
