import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TimeAgo from 'react-timeago';
import moment from 'moment';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import ptBrSettings from 'react-timeago/lib/language-strings/pt-br';

import {
  SetNotificationsCount,
  SetRecommendedCarsNotificationsCount,
} from '../../../store/actions/auction.actions';

import { encapsulateNotificationsByDate } from '../../../utils/notifications';
import { getAuctionsNotifications } from '../../../services/notifications';
import { useTempNotification } from '../../../hooks/useTempNotification';

import SimpleCard from '../Components/SimpleCard';
import { Container, List } from './styles';
import { Loading } from '../..';
import { EmptyState } from '../../enhanced/EmptyState';
import { Box, Typography } from '@mui/material';
import { ArrowForwardRounded } from '@mui/icons-material';

import { FaRegCalendar } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { RecommendedIcon } from '../../Icons';

const formatter = buildFormatter(ptBrSettings);

const CheckNowButton = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  color: #22aa52;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

export const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.875rem;
  color: #2f3741;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

function NotificationsList({ goToPageOne }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const recommendedCarsCount = useSelector(state => state.ui.recommendedCarsNotificationsCount);
  const notificationsCount = useSelector(state => state.ui.notificationsCount);
  const { id, email } = useSelector(state => state.authentication.user);
  const newOffers = useTempNotification();

  async function listNotifications() {
    setLoading(true);
    const allNotifications = await getAuctionsNotifications();
    const encapsulatedNotifications = encapsulateNotificationsByDate(allNotifications);
    setNotifications([...encapsulatedNotifications]);
    setLoading(false);
  }

  useEffect(() => {
    listNotifications();
    if (notificationsCount > 0) dispatch(SetNotificationsCount(0));
  }, [notificationsCount]);

  if (loading)
    return (
      <Container.Loading>
        <Loading />
      </Container.Loading>
    );

  function date(date) {
    if (date === moment().format('YYYY-MM-DD')) {
      return `Hoje, ${moment(date).format('dddd, DD/MM/YY')}`;
    }

    const yesterday = moment()
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
    if (date === yesterday) {
      return `Ontem, ${moment(date).format('dddd, DD/MM/YY')}`;
    }

    return (
      <span>
        <TimeAgo date={date} formatter={formatter} />, {moment(date).format('dddd, DD/MM/YY')}
      </span>
    );
  }

  return (
    <Container>
      {!!recommendedCarsCount && (
        <Box
          width={'100%'}
          display={'flex'}
          borderRadius={2}
          border={'1px solid #22AA52'}
          marginBottom={4}
        >
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'42px'}
            minHeight={'100%'}
            bgcolor={'#22AA52'}
            sx={{
              borderTopLeftRadius: 7,
              borderBottomLeftRadius: 7,
            }}
          >
            <RecommendedIcon color="#22aa52" style={{ filter: 'brightness(0) invert(1)' }} />
          </Box>

          <Box
            width={'100%'}
            display={'flex'}
            paddingLeft={'12px'}
            paddingRight={'16px'}
            justifyContent={'space-between'}
            alignSelf={'center'}
          >
            <Typography paddingY={'14px'} component={'span'} fontFamily={'Roboto'}>
              Selecionamos{' '}
              <Typography component={'span'} fontWeight={700}>
                {recommendedCarsCount}{' '}
                {recommendedCarsCount === 1 ? 'nova disputa' : 'novas disputas'}
              </Typography>{' '}
              que recomendamos para você com base em suas preferências de ofertas.{' '}
              <CheckNowButton
                onClick={() => {
                  dispatch(SetRecommendedCarsNotificationsCount());
                  goToPageOne();
                  sendEvent('preferencesTracker', {
                    dealershipId: user?.id,
                    dealershipEmail: user?.email,
                    dealershipName: user.dealershipName,
                    carResults: recommendedCarsCount,
                    eventCategory: 'Auctions',
                    eventAction: 'ClickNotification',
                    eventLabel: 'Pedir Carros',
                  });
                }}
              >
                Confira agora!
                <ArrowForwardRounded sx={{ alignSelf: 'center' }} fontSize={'small'} />
              </CheckNowButton>
            </Typography>

            <CloseBtn>
              <MdClose onClick={() => dispatch(SetRecommendedCarsNotificationsCount())} />
            </CloseBtn>
          </Box>
        </Box>
      )}

      {newOffers.length > 0 && (
        <>
          <Container.Title>Leilões próximos a finalizar</Container.Title>
          <List>
            <List.Sublist>
              {newOffers?.map((auction, key) => (
                <SimpleCard key={key} auction={auction} temperature={true} />
              ))}
            </List.Sublist>
          </List>
        </>
      )}

      {notifications.length > 0 ? (
        <>
          <Container.Title>Meus resultados</Container.Title>
          <List>
            {notifications?.map((notification, key) => (
              <div key={key}>
                <List.Title>
                  <span className="icon">
                    <FaRegCalendar />
                  </span>
                  {date(notification.date)}
                </List.Title>
                <List.Sublist>
                  {notification.data?.map((auction, key) => (
                    <SimpleCard key={key} auction={auction} temperature={false} />
                  ))}
                </List.Sublist>
              </div>
            ))}
          </List>
        </>
      ) : (
        <EmptyState
          title="Você não possui notificações"
          description="Aqui é onde você encontrará os resultados dos leilões que você participou"
        />
      )}
    </Container>
  );
}

// function mapStateToProps({ ui }) {
//   return {
//     notificationsCount: ui.notificationsCount,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     setNotificationsCount: bindActionCreators(SetNotificationsCount, dispatch),
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
export default NotificationsList;
